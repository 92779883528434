.fullTraits {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  padding: 5px;
  width: 100%;

  .traitBlock:nth-child(2n) {
    background: #dddddd;
  }
}

.traitBlock {
  text-align: left;
  padding: 5px;
  border-radius: 5px;
}

.traitName {
  font-size: 1.1rem;
  font-weight: bold;

  svg {
    transform: scale(2);
  }
}

.traitDescription {
  font-size: 0.8rem;

  svg {
    transform: scale(1.5);
    margin: 1px;
  }
}
