.equip {
  border-style: solid;
  border-radius: 5px;
  background: #ffd400;
}

.equipList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  padding: 5px;

}
.replacementImage {
  img {
    object-fit: cover;
    width: 100%;
  }
}
.topBar {
  display: grid;
  grid-template-columns: 6fr 1fr 2fr;
  padding: 5px;

  font-size: 1.2rem;
  font-weight: bold;

  svg {
    height: 25px;
    width: auto;
  }
}

.desc {
  font-size: 0.9rem;
  text-align: left;
  padding: 5px;
}

.stacked {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}
.aff {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.affIcon {
  height: 25px;
  filter: invert(100%);
  text-align: right;
}

.traitBlock {
  text-align: left;
  padding: 5px;
  border-radius: 5px;
}

.traitName {
  font-size: 1rem;
  font-weight: bold;

  svg {
    transform: scale(2);
  }
}

