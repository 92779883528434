.stacked {
  display: flex;
  flex-direction: column;
}

.reputation {
  line-height: 1.3rem;
  text-align: right;
  font-size: 1.4rem;
}

.app {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.1rem;
}

.search {
  display: flex;

  button {
    margin: 5px;
    border-radius: 5px;
  }

  @media print {
    display: none !important;
  }
}

.searchBar {
  flex-grow: 1;
}

.alias {
  font-size: 1.6rem;
}

.left {
  text-align: left;
}

.bottomAlign {
  justify-content: center;
  align-items: flex-end;
}

.topBar {
  height: 50px;
  display: grid;
  grid-template-columns: 14fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
  padding: 20px;
  position: relative;

  svg {
    height: 25px;
    width: auto;
  }
}

.small {
  font-size: 1rem;
  left: 50%;
  bottom: -14%;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  position: absolute;
}

.weapon {
  padding-top: 15px;
  display: grid;
  grid-template-columns: 4fr 2fr 1fr 1fr 4fr;
  text-align: left;
  margin-left: 15px;
  margin-right: 5px
}

.characters {
  display: grid;
  grid-template-columns: 693px 693px;
  gap: 10px;
  @media print {
    grid-template-columns: 486px 486px;
  }
}

.weaponBar {
  background: black;
  opacity: 0.75;
  border-radius: 5px;
  height: 50px;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 5px;
  font-size: 1.1rem;
  color: #ffd400;

  svg {
    fill: #ffd400;
    height: auto;
    width: 15px;
  }
}

.traitBlock {
  background: #ffd400;
  border-radius: 5px;
  height: 100px;
  width: 90%;
  margin-left: 5%;
  color: black;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.backOfCard {
  padding: 10px;
  margin: 5px;
  display: flex;
  height: 486px;
  flex-direction: column;
  overflow: scroll;
  font-size: 0.9rem;
  font-family: 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.trait {
  display: block;
  text-align: left;
}

.backTraitName {
  font-weight: bold;

  svg {
    transform: scale(1.5);
  }
}

.traitName {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  padding-top: 5px;
  font-size: 1.2rem;
  line-height: 1rem;

  svg {
    transform: scale(2);
  }
}

.traitDescription {
  font-size: 0.9rem;

  svg {
    transform: scale(1.5);
    margin: 1px;
  }
}

.statBlock {
  font-size: 2.2rem;
  display: grid;
  grid-template-columns: 12fr 8fr;
}

.stats {
  svg {
    left: -10%;
  }
}

.bigStats {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  margin-bottom: 10px;
  font-size: 3rem;

  svg {
    height: 70px;
    width: 80px;
    margin: 0;
  }
}

.affIcon {
  height: 25px;
  filter: invert(100%);
}

.mediumStats {
  display: grid;
  height: 40px;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  margin-bottom: 10px;

  .statBackground {
    height: 50px;
  }
}

.tinyStats {
  display: grid;
  height: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;

  .statBackground {
    height: 45px;
  }
}

.statContainer {
  height: 75px;
  position: relative;
}

.yellow {
  color: #ffd400;
}

.imageText {
  position: absolute;
  left: 20%;
  bottom: 10%;
}

.imageDoubleText {
  position: absolute;
  right: 50%;
  bottom: 10%;
}

.front {
  display: flex;
  margin: 5px;
}

.card {
  min-height: 496px;
  height: 496px;
  min-width: 693px;
  width: 693px;
  border-radius: 25px;
  @media print {
    transform: scale(0.7);
  }

}

.attackText {
  position: absolute;
  left: 50%;
  bottom: 35%;
}

.tinyText {
  position: absolute;
  left: 35%;
  bottom: 40%;
}

.statBackground {
  position: absolute;
  width: auto;
}

.aff {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 5px;
  svg {
    height: 25px;
    width: 25px;
  }
}

.sideways {
  transform-origin: 0 0;
  transform: rotate(-90deg);
  top: 50%;
  position: absolute;
}

.weaponTrait {
  line-height: 0.9rem;
  font-size: 1rem;
}
